import React, { useContext, useEffect, useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { LayoutContext, LoadingButton, fetchGet, fetchPost } from "wcz-layout";
import AttendeeSelfConfirmEventCard from "../components/attendeeSelfConfirm/AttendeeSelfConfirmEventCard";
import { apiUrl } from "../utils/BaseUrl";
import Event from "../models/Event";
import SelfConfirmAttendeeCard from "../components/attendeeSelfConfirm/SelfConfirmAttendeeCard";
import ConfirmAttendeeDto from "../models/ConfirmAttendeeDto";
import { Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { indigo } from "@mui/material/colors";

export default function AttendeeSelfConfirm() {
    const { user, changeTitle } = useContext(LayoutContext);
    const [isConfirmed, SetIsConfirmed] = useState(false);
    const { eventId, eventSubId } = useParams<{ eventId: string, eventSubId: string }>();

    const { data: event } = useQuery<Event, Response>(["event-attendeeSelfConfirm"], ({ signal }) => fetchGet(apiUrl + "ts-attendance/v1/event/" + eventId + "/" + eventSubId, signal));

    useQuery<boolean, Response>(["attendeeSelfConfirm-checkIsConfirmed"], ({ signal }) => fetchGet(apiUrl + "ts-attendance/v1/attendee/isconfirmed/" + eventId + "/" + eventSubId + "/" + user.id.toUpperCase(), signal), {
        onSuccess: (data) => SetIsConfirmed(data),
    });

    const { mutate: mutateConfirmAttendee } = useMutation((attendee: ConfirmAttendeeDto) => fetchPost(`${apiUrl}ts-attendance/v1/attendee/confirm`, attendee), {
        onSuccess: () => SetIsConfirmed(true),
    });

    useEffect(() => {
        changeTitle("Attendee Self-Confirm");                
    }, []);

    const submit = () => {
        if (!eventSubId)
            return;

        mutateConfirmAttendee({
            eventId: eventId as string,
            eventSubId: + eventSubId,
            employeeId: user.id.toUpperCase()
        });  
    };

    return (
        <React.Fragment>
            {event && <AttendeeSelfConfirmEventCard event={event} />}
            <SelfConfirmAttendeeCard />
          
            {!isConfirmed && <LoadingButton variant="outlined" onClick={() => submit()}>Confirm my attendance</LoadingButton>}

            <Dialog fullScreen open={isConfirmed}>
                <DialogContent sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ marginBottom: "50px"}} >
                        <img src="/logo192.png" alt="Confirmed" width="96px" />
                    </div>
                    <Typography sx={{ textAlign: "center" }} component="div" variant="h6" color={indigo[500]}>
                        Attendance has been confirmed!
                    </Typography>
                    <Typography component="div" variant="subtitle1">
                        You can close this app :-)
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ margin: "auto" }}>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}