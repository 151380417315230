import { InputAdornment, OutlinedInput, Box } from "@mui/material";
import { Search } from "@mui/icons-material";
import React, { useContext, useState, useEffect, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchGet, hasRole, LayoutContext } from "wcz-layout";
import EventCard from "../components/home/EventCard";

import Event from "../models/Event";
import { apiUrl } from "../utils/BaseUrl";

export default function Home() {
    const { user, changeTitle, t } = useContext(LayoutContext);
    const [search, setSearch] = useState("");
    const methodUrl: string = useMemo(() => {
        if (user.id) {
            if (!hasRole(["sg_WCZ_Role-TrainingAdmin"])) {
                return "event/" + user.id.toUpperCase();
            }            
        }   
        return "event";      
    }, [user.id]);

    const { data: events } = useQuery<Event[], Response>(["events"], ({ signal }) => fetchGet(apiUrl + "ts-attendance/v1/" + methodUrl, signal), {
        enabled: !!user.name,
    });

    useEffect(() => {
        changeTitle("Training Events");
    }, []);

    return (
        <React.Fragment>
            <Box sx={{ p: "7px", pt: "10px" }}>
                <OutlinedInput
                    placeholder={t("SearchEvents")}
                    fullWidth
                    size="small"
                    onChange={e => setSearch(e.target.value)}
                    startAdornment={<InputAdornment position="start"><Search /></InputAdornment>}            
                />
            </Box>
            
            {
                events?.map((event: Event) => {
                    if (search.trim() === "") 
                        return <EventCard event={event} />;
                    
                    if (event.titleCz.toLowerCase().includes(search.toLowerCase()) 
                     || event.titleEn.toLowerCase().includes(search.toLowerCase())
                     || event.id.toLowerCase().includes(search.toLowerCase()) 
                     || event.trainer.toLowerCase().includes(search.toLowerCase()) 
                     || event.location.toLowerCase().includes(search.toLowerCase())
                     || event.status.toLowerCase().includes(search.toLowerCase()))
                        return <EventCard event={event} />;
                    
                    return null;
                })
            }
        </React.Fragment>
    );
}