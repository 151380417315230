import { Home as HomeIcon } from "@mui/icons-material";
import { indigo, pink } from "@mui/material/colors";
import { useMemo } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LayoutProvider, LeftDrawerItem, useUser } from "wcz-layout";
import packageJson from "../package.json";
import Home from "./pages/Home";
import EventAttendees from "./pages/EventAttendees";
import AttendeeSelfConfirm from "./pages/AttendeeSelfConfirm";

export const App: React.FC = () => {
    const user = useUser();

    const leftDrawerItems: LeftDrawerItem[] = useMemo(() => [
        { title: "Layout.Home", path: "/", icon: <HomeIcon fontSize="large" /> },
    ], [user]);

    return (
        <BrowserRouter>
            <LayoutProvider title={packageJson.name} appVersion={packageJson.version} primaryColor={indigo[500]} secondaryColor={pink[500]} leftDrawerItems={leftDrawerItems}>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/attendees/:eventId/:eventSubId' element={<EventAttendees />} />
                    <Route path='/self-confirm/:eventId/:eventSubId' element={<AttendeeSelfConfirm />} />
                </Routes>
            </LayoutProvider>
        </BrowserRouter>
    );
};