import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { indigo, blue, green, orange } from "@mui/material/colors";
import { Room, PersonPin  } from "@mui/icons-material";
import moment from "moment";

import Event from "../../models/Event";

interface EventCardProps {
    event: Event,
}

export default function AttendeeSelfConfirmEventCard(props: EventCardProps) {

    const borderColor = () => {
        switch (props.event.status) {
            case "Open": return green[500];
            case "Ongoing": return blue[500];
            case "Finished": return orange[500];
            case "Closed": return blue[900];
            default: return "inherit";
        }
    };    

    return (
        <Card square elevation={3} sx={{ m: "4px", borderBottom: "solid 7px", borderBottomColor: borderColor }}>
            <CardContent sx={{ pb: "4px!important" }}>
                <Typography variant="body2" color="text.secondary">
                    {moment(props.event.start).format("MMM DD").toUpperCase()}, {moment(props.event.start).format("hh:mm")} - {moment(props.event.end).format("hh:mm")}
                </Typography>
                <Typography variant="h6">
                    {props.event.titleCz}
                </Typography>                
                <Typography variant="body2" color={indigo[500]}  sx={{ display: "flex", alignItems: "center", flexWrap: "wrap",  }}>
                    <Room sx={{ fontSize: "1rem" }}/>{props.event.location}
                </Typography> 
                <Typography variant="body2" color="secondary"  sx={{ display: "flex", alignItems: "center", flexWrap: "wrap",  }}>
                    <PersonPin sx={{ fontSize: "1rem" }}/>{props.event.trainer}
                </Typography>                   
            </CardContent>               
        </Card>            
    );
}