import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { fetchGet, fetchPost, isAndroid, isMobile, LayoutContext } from "wcz-layout";
import AttendeeCard from "../components/eventAttendees/AttendeeCard";

import AttendeesEventCard from "../components/eventAttendees/AttendeesEventCard";
import LastScannedAttendee from "../components/eventAttendees/LastScannedAttendee";
import Attendee from "../models/Attendee";
import { AttendeesFilter } from "../models/enums/AttendeesFilter";
import Event from "../models/Event";

import { ContentCopy } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";
import QRCode from "qrcode.react";
import ConfirmAttendeeDto from "../models/ConfirmAttendeeDto";
import { apiUrl } from "../utils/BaseUrl";
import { readNfcTag } from "../utils/Nfc";


export default function EventAttendees() {
    const { snackbar, changeTitle } = useContext(LayoutContext);
    const [nfcUsn, setNfcUsn] = useState("");
    const { eventId, eventSubId } = useParams<{ eventId: string, eventSubId: string }>();
    const [attendees, setAttendees] = useState<Attendee[]>();
    const [confirmed, setConfirmed] = useState(0);
    const [notConfirmed, setNotConfirmed] = useState(0);
    const [filter, setFilter] = useState<AttendeesFilter>(AttendeesFilter.All);
    const [lastScanned, setLastScanned] = useState<Attendee>();

    const { data: event } = useQuery<Event, Response>(["event-attendees"], ({ signal }) => fetchGet(apiUrl + "ts-attendance/v1/event/" + eventId + "/" + eventSubId, signal));

    const { refetch: refetchAttendees } = useQuery<Attendee[], Response>(["attendees", eventId, eventSubId], ({ signal }) => fetchGet(apiUrl + "ts-attendance/v1/attendee/" + eventId + "/" + eventSubId, signal), {
        refetchInterval: !isMobile ? 3000 : false,
        refetchOnWindowFocus: !isMobile,
        onSuccess: (data) => setAttendees(data),
    });

    const { mutate: mutateConfirmAttendee } = useMutation((attendee: ConfirmAttendeeDto) => fetchPost(`${apiUrl}ts-attendance/v1/attendee/confirm`, attendee), {
        onSuccess: (data) => { setLastScanned(data); refetchAttendees(); },
    });

    useEffect(() => {        
        changeTitle("Attendees");

        if (isAndroid) {
            const abortController = new AbortController();
            abortController.signal.onabort = event => {
                // All NFC operations have been aborted.
            };

            readNfcTag({
                onSuccess: (data) => setNfcUsn(data),
                onError: (message) => snackbar({ message: message, severity: "error" }),
                abortController: abortController
            });

            return () => abortController.abort();
        }

        // eslint-disable-next-line
    }, []);

    useQuery(["nfcTag", nfcUsn], ({ signal }) => fetchGet(`https://api.wistron.eu/met/v1/employeetag/nfcusn/${nfcUsn}`, signal), {
        enabled: !!nfcUsn,
        onSuccess: (data) => {
            if (data.employeeId) {
                confirmAttendance(data.employeeId);
            }
        }
    });

    useEffect(() => {
        let confirmedAttendees = attendees?.filter(a => a.isConfirmed === true).length ?? 0;
        setConfirmed(confirmedAttendees);
        let notConfirmedAttendees = attendees?.filter(a => a.isConfirmed === false).length ?? 0;
        setNotConfirmed(notConfirmedAttendees);
    }, [attendees]);

    const confirmAttendance = (employeeId: string) => {
        if (!eventSubId)
            return;

        mutateConfirmAttendee({
            eventId: eventId as string,
            eventSubId: +eventSubId,
            employeeId: employeeId
        });
    };

    const filterAttendees = (attendeesFilter: AttendeesFilter) => {
        console.log("filtruju " + attendeesFilter);
        setFilter(attendeesFilter);
    };

    const copyToClipboard = () => {
        let url = `https://${window.location.host}/self-confirm/${eventId}/${eventSubId}`;
        navigator.clipboard.writeText(url).then(function () {
            snackbar({ message: "Link URL from the QR copied to clipboard!" });
        }, function (err) {
            console.error("Async: Could not copy text: ", err);
        });
    };

    return (
        <React.Fragment>
            {event && <AttendeesEventCard event={event} confirmed={confirmed} notConfirmed={notConfirmed} filterAttendees={filterAttendees} />}
            {lastScanned && <LastScannedAttendee attendee={lastScanned} />}
            <Box sx={{ display: "flex", flexFlow: "row wrap" }}>
                <Box sx={{ width: { xs: "100%", md: "70%" } }}>
                    {
                        attendees?.map(attendee => {
                            if (filter === AttendeesFilter.Confirmed && attendee.isConfirmed) {
                                return <AttendeeCard attendee={attendee} />;
                            }
                            else if (filter === AttendeesFilter.NotConfirmed && !attendee.isConfirmed) {
                                return <AttendeeCard attendee={attendee} />;
                            }
                            else if (filter === AttendeesFilter.All) {
                                return <AttendeeCard attendee={attendee} />;
                            }

                            return null;
                        })
                    }
                </Box>
                <Box sx={{ display: { xs: "none", md: "flex" }, width: "30%", alignItems: "center", justifyContent: "center" }}>
                    <Tooltip arrow title={<Typography onClick={copyToClipboard} component="div" variant="body2" sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", cursor: "pointer" }}><ContentCopy /> Copy URL to clipboard</Typography>}>
                        <Box>                        
                            <QRCode value={`https://${window.location.host}/self-confirm/${eventId}/${eventSubId}`} size={256} includeMargin={true} level="H" imageSettings={{ src: "/logo192.png", excavate: true, width: 50, height: 50 }} />
                        </Box>
                    </Tooltip>
                </Box>
            </Box>
        </React.Fragment>
    );
}
