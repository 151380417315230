import React, { useState, useEffect } from "react";
import { Card, CardContent, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Grid, Chip, Button, IconButton } from "@mui/material";
import { indigo, blue, green, orange } from "@mui/material/colors";
import { Room, PersonPin, QrCode2, Check, Close } from "@mui/icons-material";
import QRCode from "qrcode.react";
import moment from "moment";

import Event from "../../models/Event";
import { AttendeesFilter } from "../../models/enums/AttendeesFilter";

interface EventCardProps {
    event: Event,
    confirmed: number,
    notConfirmed: number,
    filterAttendees: (attendeesFilter: AttendeesFilter) => void,
}

export default function EventCard(props: EventCardProps) {
    const [open, setOpen] = useState(false);
    const [attendeesFilter, setAttendeesFilter] = useState<AttendeesFilter>();
    const { filterAttendees } = props;

    useEffect(() => {
        if (attendeesFilter) filterAttendees(attendeesFilter);
    }, [attendeesFilter, filterAttendees]);

    const borderColor = () => {
        switch (props.event.status) {
            case "Open": return green[500];
            case "Ongoing": return blue[500];
            case "Finished": return orange[500];
            case "Closed": return blue[900];
            default: return "inherit";
        }
    };

    const showQrCode = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const applyFilter = (sender: "confirmedChip" | "notConfirmedChip") => {
        if (sender === "confirmedChip") {
            if (attendeesFilter !== AttendeesFilter.Confirmed) {
                setAttendeesFilter(AttendeesFilter.Confirmed);
            }
            else {
                setAttendeesFilter(AttendeesFilter.All);                                
            }
        }
        else if (sender === "notConfirmedChip") {
            if (attendeesFilter !== AttendeesFilter.NotConfirmed) {
                setAttendeesFilter(AttendeesFilter.NotConfirmed);
            }
            else {
                setAttendeesFilter(AttendeesFilter.All);                                
            }
        }
    };

    return (
        <React.Fragment>
            <Card square elevation={3} sx={{ m: "4px", borderBottom: "solid 7px", borderBottomColor: borderColor }}>
                <CardContent sx={{ pb: "4px!important" }}>
                    <Typography variant="body2" color="text.secondary">
                        {moment(props.event.start).format("YYYY MMM DD").toUpperCase()}, {moment(props.event.start).format("HH:mm")} - {moment(props.event.end).format("HH:mm")}
                    </Typography>
                    <Typography variant="h6">
                        {props.event.titleCz}
                    </Typography>                
                    <Typography variant="body2" color={indigo[500]}  sx={{ display: "flex", alignItems: "center", flexWrap: "wrap",  }}>
                        <Room sx={{ fontSize: "1rem" }}/>{props.event.location}
                    </Typography> 
                    <Typography variant="body2" color="secondary"  sx={{ display: "flex", alignItems: "center", flexWrap: "wrap",  }}>
                        <PersonPin sx={{ fontSize: "1rem" }}/>{props.event.trainer}
                    </Typography> 
                    <Grid container spacing={2} alignItems="center" pt="5px">
                        <Grid item>
                            <Chip 
                                label={"confirmed: " + props.confirmed} 
                                icon={<Check />} 
                                clickable={false} 
                                sx={{ cursor: "pointer" }} 
                                variant={attendeesFilter === AttendeesFilter.Confirmed ? "filled" : "outlined"} 
                                onClick={() => applyFilter("confirmedChip")} 
                                color="default" 
                                size="small" 
                            />
                        </Grid>
                        <Grid item>
                            <Chip 
                                label={"not confirmed: " + props.notConfirmed} 
                                icon={<Close />} 
                                clickable={false} 
                                sx={{ cursor: "pointer" }} 
                                variant={attendeesFilter === AttendeesFilter.NotConfirmed ? "filled" : "outlined"} 
                                onClick={() => applyFilter("notConfirmedChip")} 
                                color="default" 
                                size="small" 
                            />
                        </Grid>
                        <Grid item sx={{ ml: "auto" }}>
                            <IconButton onClick={() => showQrCode()}>
                                <QrCode2 />
                            </IconButton>
                        </Grid>                    
                    </Grid>                   
                </CardContent>               
            </Card>

            <Dialog fullScreen open={open}>
                <DialogTitle>Hele QR kod!</DialogTitle>
                <DialogContent sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <QRCode value={`https://${window.location.host}/self-confirm/${props.event.id}/${props.event.subId}`} size={256} level="H" includeMargin={true} imageSettings={{ src: "/logo192.png", excavate: true, width: 50, height: 50 }} />
                    {/* <QRCode value="https://ts-feedback.dev.wistron.eu" renderAs="canvas" size={200} level="H" fgColor={indigo[900]} /> */}
                </DialogContent>
                <DialogActions sx={{ margin: "auto" }}>
                    <Button variant="outlined" size="large" sx={{ mb: "15px" }} onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}