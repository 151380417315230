import React from "react";
import { useNavigate } from "react-router";
import { Card, CardContent, Box, Typography } from "@mui/material";
import { indigo, blue, green, orange } from "@mui/material/colors";
import { Room, PersonPin } from "@mui/icons-material";
import moment from "moment";

import Event from "../../models/Event";

interface EventCardProps {
    event: Event,
}

export default function EventCard(props: EventCardProps) {
    const navigate = useNavigate();

    const borderColor = () => {
        switch (props.event.status) {
            case "Open": return green[500];
            case "Ongoing": return blue[500];
            case "Finished": return orange[500];
            case "Closed": return blue[900];
            default: return "inherit";
        }
    };

    return (
        <Card square elevation={3} sx={{ display: "flex", cursor: "pointer", m: "7px", borderLeft: "solid 5px", borderLeftColor: borderColor }} onClick={() => navigate("/attendees/" + props.event.id + "/" + props.event.subId)}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <CardContent sx={{ flex: "auto auto auto" }}>
                    <Typography component="div" variant="h6" color="secondary">
                        {moment(props.event.start).format("YYYY").toUpperCase()}
                    </Typography>
                    <Typography component="div" variant="h6" color={indigo[500]}>
                        {moment(props.event.start).format("MMM").toUpperCase()}
                    </Typography>
                    <Typography component="div" variant="h4">
                    {moment(props.event.start).format("DD").toUpperCase()}
                    </Typography>                    
                </CardContent>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <CardContent sx={{ flex: "auto auto auto" }}>
                    <Typography component="div" variant="h6">
                        {props.event.titleCz}
                    </Typography>
                    <Typography component="div" variant="subtitle1">
                        {moment(props.event.start).format("HH:mm")} - {moment(props.event.end).format("HH:mm")}
                    </Typography>
                    <Typography component="div" variant="subtitle1" sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", }}>
                        <Room fontSize="small" sx={{ color: indigo[500] }}/>{props.event.location}
                    </Typography>
                    <Typography component="div" variant="subtitle1" sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", }}>
                        <PersonPin fontSize="small" color="secondary"/>{props.event.trainer}
                    </Typography>
                </CardContent>                
            </Box>                    
        </Card>
    );
}