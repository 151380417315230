import React from "react";
import { Card, Typography, Chip } from "@mui/material";
import { green } from "@mui/material/colors";
import { Person } from "@mui/icons-material";

import Attendee from "../../models/Attendee";

interface AttendeeCardProps {
    attendee: Attendee,
}

export default function AttendeeCard(props: AttendeeCardProps) {

    return (
        <Card square elevation={3} sx={{ display: "flex", justifyContent: "space-between", p: "7px", pr: "10px" }}>
            <Typography component="div" sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", }}>
                <Person sx={{ color: props.attendee.isConfirmed ? green[500] : "text.secondary" }} />
                {props.attendee.employeeName + " (" + props.attendee.employeeId + ")"}
            </Typography>
            <Typography component="div">
                <Chip label={props.attendee.department} size="small" variant="outlined" color="primary" />                
            </Typography>
        </Card>
    );
}