import React from "react";
import { Card, CardHeader, Typography, Avatar } from "@mui/material";
import { green } from "@mui/material/colors";
import { Person } from "@mui/icons-material";

import Attendee from "../../models/Attendee";

interface AttendeeCardProps {
    attendee: Attendee,
}

export default function LastScannedAttendee(props: AttendeeCardProps) {

    return (
        <Card square elevation={3} sx={{ display: "flex", flexDirection: "column", m: "4px" }}>
            <Typography sx={{ fontSize: 14, pl: "15px", pt: "5px" }} color="text.secondary">
                Last Scanned:
            </Typography>

            <CardHeader
                avatar={
                <Avatar sx={{ bgcolor: green[500] }}>
                    <Person />
                </Avatar>
                }                
                title={props.attendee.employeeName + " (" + props.attendee.employeeId + ")"}
                subheader={props.attendee.department}
            />
        </Card>
    );
}